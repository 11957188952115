import init from '../accordion/dist/uswitch-ev-utils-critical-accordion.esm.js';
import init$1 from '../accordion-preview/dist/uswitch-ev-utils-critical-accordion-preview.esm.js';
import init$2 from '../tabs/dist/uswitch-ev-utils-critical-tabs.esm.js';
import init$3 from '../carousel/dist/uswitch-ev-utils-critical-carousel.esm.js';
import init$4 from '../postcode-capture/dist/uswitch-ev-utils-critical-postcode-capture.esm.js';
import init$5 from '../email-capture/dist/uswitch-ev-utils-critical-email-capture.esm.js';
import '@babel/runtime/helpers/toConsumableArray';
import '@babel/runtime/helpers/slicedToArray';
import '../../get-window/dist/uswitch-ev-utils-get-window.esm.js';

var tryAll = function tryAll(fns) {
  fns.forEach(function (fn) {
    try {
      fn();
    } catch (e) {
      var _window, _window$Sentry;
      (_window = window) === null || _window === void 0 ? void 0 : (_window$Sentry = _window.Sentry) === null || _window$Sentry === void 0 ? void 0 : _window$Sentry.captureException(e);
    }
  });
};
tryAll([init, init$1, init$2, init$3, init$4, init$5]);
