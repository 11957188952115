import _toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';

// TODO: Cleanup this code

var init = function init() {
  var tabs = _toConsumableArray(document.querySelectorAll('[data-spark-tabs]'));
  tabs.forEach(function (tab) {
    var _buttonClasses$find, _buttonClasses$find2;
    if (tab.dataset.sparkHydrated === 'true') {
      return;
    }
    var list = tab.childNodes[0];
    var panels = tab.childNodes[1];
    var buttons = list.childNodes[0].childNodes;
    var buttonClasses = Array.from(buttons).map(function (b) {
      return {
        class: b.getAttribute('class'),
        disabled: b.getAttribute('disabled') === ''
      };
    });
    var activeClassName = (_buttonClasses$find = buttonClasses.find(function (obj) {
      return !obj.disabled;
    })) === null || _buttonClasses$find === void 0 ? void 0 : _buttonClasses$find.class;
    var disabledClassName = (_buttonClasses$find2 = buttonClasses.find(function (obj) {
      return obj.disabled;
    })) === null || _buttonClasses$find2 === void 0 ? void 0 : _buttonClasses$find2.class;
    buttons.forEach(function (button, index) {
      button.addEventListener('click', function () {
        var panel = panels.childNodes[index];
        button.setAttribute('disabled', '');
        var display = window.getComputedStyle(panel, null).display;
        if (display === 'none') {
          var _button$getAttribute;
          panel.style.display = 'block';
          button.setAttribute('class', (_button$getAttribute = button.getAttribute('class')) === null || _button$getAttribute === void 0 ? void 0 : _button$getAttribute.replace(activeClassName, disabledClassName));
          buttons.forEach(function (b, i) {
            if (i !== index) {
              var _b$getAttribute;
              b.removeAttribute('disabled');
              b.setAttribute('class', (_b$getAttribute = b.getAttribute('class')) === null || _b$getAttribute === void 0 ? void 0 : _b$getAttribute.replace(disabledClassName, activeClassName));
            }
          });
          panels.childNodes.forEach(function (p, i) {
            if (i !== index) {
              p.style.display = 'none';
            }
          });
        }
      });
    });
  });
};

export { init as default };
