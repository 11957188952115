import _toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';

var init = function init() {
  var accordions = _toConsumableArray(document.querySelectorAll('[data-spark-accordion]'));
  accordions.forEach(function (accordion) {
    accordion.addEventListener('click', function (element) {
      var _element$target$paren, _element$target$paren2;
      if (accordion.dataset.sparkHydrated === 'true') {
        return;
      }
      var target = element.target;
      if ((_element$target$paren = element.target.parentNode) !== null && _element$target$paren !== void 0 && (_element$target$paren2 = _element$target$paren.dataset) !== null && _element$target$paren2 !== void 0 && _element$target$paren2.sparkAccordionButton) {
        target = element.target.parentNode;
      }
      var _target = target,
        dataset = _target.dataset;
      if (dataset.sparkAccordionButton) {
        var open = dataset.sparkAccordionOpen === 'true';
        var content = target.nextSibling;

        // Money specific
        var icon = target.querySelector('[data-ev-accordion]');

        // Uswitch specific
        var openIcon = target.querySelector('[data-ev-accordion-open-icon');
        var closeIcon = target.querySelector('[data-ev-accordion-close-icon');
        target.setAttribute('data-spark-accordion-open', !open);
        if (open) {
          content.style.height = '0px';
          content.style.overflow = 'hidden';
          content.childNodes[0].style.display = 'none';
          content.childNodes[0].childNodes[0].style.opacity = 0;
          content.childNodes[0].childNodes[0].style.visibility = 'hidden';
          if (icon) {
            icon.style.transform = 'rotate(0.5turn)';
          }
          if (openIcon && closeIcon) {
            openIcon.style.display = 'block';
            closeIcon.style.display = 'none';
          }
        } else {
          content.style.height = 'auto';
          content.style.overflow = 'visible';
          content.childNodes[0].style.display = 'block';
          content.childNodes[0].childNodes[0].style.opacity = 1;
          content.childNodes[0].childNodes[0].style.visibility = 'visible';
          if (icon) {
            icon.style.transform = 'none';
          }
          if (openIcon && closeIcon) {
            openIcon.style.display = 'none';
            closeIcon.style.display = 'block';
          }
        }
      }
    });
  });
};

export { init as default };
