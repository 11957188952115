import _slicedToArray from '@babel/runtime/helpers/esm/slicedToArray';
import _toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';

var init = function init() {
  var carousels = _toConsumableArray(document.querySelectorAll('.eevee-carousel-wrapper'));
  carousels.forEach(function (carousel) {
    var dots = _toConsumableArray(carousel.querySelectorAll('.eevee-carousel-dot'));
    var slides = _toConsumableArray(carousel.querySelectorAll('.eevee-carousel-slide'));
    var observer = new IntersectionObserver(function (entries) {
      var _inView$target;
      var activeDots = _toConsumableArray(carousel.querySelectorAll('.eevee-carousel-active-dot'));
      var _entries$filter = entries.filter(function (_ref) {
          var isIntersecting = _ref.isIntersecting;
          return isIntersecting;
        }),
        _entries$filter2 = _slicedToArray(_entries$filter, 1),
        inView = _entries$filter2[0];
      var getElementInview = inView === null || inView === void 0 ? void 0 : (_inView$target = inView.target) === null || _inView$target === void 0 ? void 0 : _inView$target.getAttribute('data-eevee-carousel-index');
      var elIndex = parseInt(getElementInview, 10);
      if (!isNaN(elIndex)) {
        var _dots$elIndex, _dots$elIndex$classLi;
        activeDots.forEach(function (ad) {
          var _ad$classList;
          return ad === null || ad === void 0 ? void 0 : (_ad$classList = ad.classList) === null || _ad$classList === void 0 ? void 0 : _ad$classList.remove('eevee-carousel-active-dot');
        });
        (_dots$elIndex = dots[elIndex]) === null || _dots$elIndex === void 0 ? void 0 : (_dots$elIndex$classLi = _dots$elIndex.classList) === null || _dots$elIndex$classLi === void 0 ? void 0 : _dots$elIndex$classLi.add('eevee-carousel-active-dot');
      }
    }, {
      rootMargin: '0%',
      threshold: 1
    });
    slides.forEach(function (slide) {
      // set an observer on each slide
      observer.observe(slide);
    });
    dots.forEach(function (dot, i) {
      var currentImage = carousel.querySelector("[data-eevee-carousel-index=\"".concat(i, "\"]"));
      dot.addEventListener('keydown', function (event, i) {
        if (event.keyCode === 13) {
          currentImage.scrollIntoView({
            block: 'nearest',
            inline: 'center',
            behavior: 'smooth'
          });
        }
      });
      dot.addEventListener('click', function () {
        currentImage.scrollIntoView({
          block: 'nearest',
          inline: 'center',
          behavior: 'smooth'
        });
      });
    });
  });
};

export { init as default };
