import _toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';

var elements = _toConsumableArray(document.getElementsByClassName('sticky')).slice(0, 1).map(function (el) {
  return {
    el: el,
    isFixed: false,
    top: 0
  };
});
var setPositionRelative = function setPositionRelative(el) {
  el.style.position = 'relative';
  el.style.width = '100%';
};
var setFixed = function setFixed() {
  elements.forEach(function (item) {
    var _el$getAttribute;
    var el = item.el,
      isFixed = item.isFixed;
    var parentRect = el.parentNode.getBoundingClientRect();
    var rect = el.getBoundingClientRect();
    var shouldBeFixed = parentRect.top < 0;
    if (shouldBeFixed && !isFixed) {
      el.classList.add('sticky-fixed');
      item.isFixed = true;
      var widthPx = el.offsetWidth + 'px';
      el.style.position = 'fixed';
      el.style.width = widthPx;
    } else if (!shouldBeFixed) {
      el.classList.remove('sticky-fixed');
      item.isFixed = false;
      setPositionRelative(el);
    }

    // responsive settings
    var tabletBreakpoint = (_el$getAttribute = el.getAttribute('data-breakpoints')) === null || _el$getAttribute === void 0 ? void 0 : _el$getAttribute.split(',').pop().slice(0, -2);
    if (tabletBreakpoint >= window.screen.width) {
      setPositionRelative(el);
    } else {
      highlightLink();
    }

    // scroll to bottom of parent
    if (parentRect.y + parentRect.height < rect.height) {
      el.parentNode.style.display = 'flex';
      el.parentNode.style.alignItems = 'flex-end';
      el.style.position = 'relative';
      item.isFixed = false;
    } else el.parentNode.style.display = 'block';
  });
};
var currentlyHighlightedListItem;
var measureElements = function measureElements() {
  elements.forEach(function (item) {
    item.top = item.el.parentNode.getBoundingClientRect().top + window.pageYOffset;
  });
  setFixed();
};
var highlightLink = function highlightLink() {
  elements.forEach(function (item) {
    var el = item.el;
    var links = _toConsumableArray(el.querySelectorAll('ul > li > a'));
    if (links.length) {
      var h2elements = _toConsumableArray(el.parentElement.previousElementSibling.querySelectorAll('h2'));
      var applicableH2elements = h2elements.filter(function (h2element) {
        return links.find(function (link) {
          var href = link.getAttribute('href');
          if (href && href.startsWith('#') && href.length === h2element.id.length + 1) {
            return href.indexOf(h2element.id, 1) !== -1;
          }
          return false;
        });
      });
      if (applicableH2elements.length) {
        var minTopDistanceObj = {
          id: h2elements[0].id,
          y: Math.abs(h2elements[0].getBoundingClientRect().y),
          instance: h2elements[0]
        };
        applicableH2elements.forEach(function (h2element) {
          var y = Math.abs(h2element.getBoundingClientRect().y);
          if (y < minTopDistanceObj.y) minTopDistanceObj = {
            id: h2element.id,
            y: y,
            instance: h2element
          };
        });
        links.some(function (link) {
          var href = link.getAttribute('href');
          if (href && href.startsWith('#') && href.length === minTopDistanceObj.id.length + 1 && href.indexOf(minTopDistanceObj.id, 1) !== -1) {
            var parent = link.parentElement;
            if (currentlyHighlightedListItem !== parent) {
              if (currentlyHighlightedListItem) {
                currentlyHighlightedListItem.classList.remove('active-sidenav-item');
              }
              currentlyHighlightedListItem = parent;
              currentlyHighlightedListItem.classList.add('active-sidenav-item');
            }
            return true;
          }
          return false;
        });
      }
    }
  });
};
if (elements.length > 0) {
  measureElements();
  window.addEventListener('scroll', setFixed, {
    passive: true
  });
  window.addEventListener('resize', measureElements);
}
