var init = function init() {
  var submitButtons = document.querySelectorAll('.ev-postcode-lead-capture__submit');
  if (!submitButtons.length) {
    return;
  }
  submitButtons.forEach(function (submitButton) {
    submitButton.addEventListener('click', function (element) {
      element.target.dispatchEvent(new CustomEvent('leadCaptureFormSubmitted', {
        bubbles: true,
        detail: {}
      }));
    });
  });
  var leadCaptureForms = document.querySelectorAll('.ev-lead-capture');
  leadCaptureForms.forEach(function (form) {
    var actionUrl = new URL(form.getAttribute('action'), window.location.origin);
    var currentPageParams = new URL(document.location).searchParams;
    var allowList = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_term', 'utm_content'];
    currentPageParams.forEach(function (val, key) {
      if (allowList.includes(key)) {
        var _input = document.createElement('input');
        _input.setAttribute('type', 'hidden');
        // prepend an _ to prevent breaking GA sessions
        _input.setAttribute('name', "_".concat(key));
        _input.setAttribute('value', val);
        form.querySelector('[data-component-name="lead-capture-form"]').appendChild(_input);
      }
    });
    var input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', "referer");
    input.setAttribute('value', document.referrer);
    form.querySelector('[data-component-name="lead-capture-form"]').appendChild(input);
    form.setAttribute('action', actionUrl.href);
  });
};

export { init as default };
