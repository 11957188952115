import 'lazysizes'
import '@uswitch/ev-utils/critical'
import '@uswitch/ev-utils/sticky-fixed'
import getWindow from '@uswitch/ev-utils/get-window'
import '@uswitch/spark/dist/styles.css'
import 'lite-youtube-embed/src/lite-yt-embed.css'
import 'lite-youtube-embed/src/lite-yt-embed.js'

window.onload = function attachTracking() {
  const anchors = [...document.querySelectorAll('a[data-nerd=true]')]
  anchors.forEach(anchor => {
    const textContent = anchor.textContent
    const props = JSON.parse(anchor.getAttribute('data-nerd-props'))
    anchor.setAttribute('data-nerd-props', JSON.stringify({ ...props, element_text: textContent }))
  })
}

const addDestinationFilterForEvents = events => {
  const w = getWindow()
  w.nerdEvents.addDestinationFilter(({ payload, context, destinations, skip }) => {
    if (events.includes(destinations.segment.getEventName(payload.name, payload.nerdAttributes))) {
      return [destinations.segment]
    }

    return skip()
  })
}

addDestinationFilterForEvents([
  'Broadband Form Step Submitted',
  'Broadband Form Submitted',
  'Element Clicked',
  'Element Viewed',
  'Experiment Assigned',
  'Experiment Loaded',
  'Experiment Viewed',
  'Form Errored',
  'Form Loaded',
  'Form Step Submitted',
  'Form Submitted',
  'Form Completed',
  'Product Clicked',
  'Product Clickout',
  'Product List Loaded',
  'Product List Viewed',
  'Product Viewed',
  'Promotion Clicked',
  'Promotion Viewed',
])

console.log('client-critical-js')
