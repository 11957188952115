import getWindow from '../../../get-window/dist/uswitch-ev-utils-get-window.esm.js';

var init = function init() {
  var emailCaptureForms = document.querySelectorAll('.ev-email-capture-form');
  if (!emailCaptureForms.length) return;
  var uConsent = function uConsent(method) {
    var _getWindow$uConsent;
    if (!getWindow().uConsent) return;
    if (!Object.prototype.hasOwnProperty.call(getWindow().uConsent, method)) return;
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return (_getWindow$uConsent = getWindow().uConsent)[method].apply(_getWindow$uConsent, args);
  };
  emailCaptureForms.forEach(function (form) {
    var uconsentForm = form.getElementsByClassName('uconsent')[0];
    var uconsentFormInput = form.getElementsByClassName('ev-email-capture-form-input')[0];
    var uconsentFormSuccess = form.getElementsByClassName('ev-email-capture-form-success')[0];
    var uconsentFormError = form.getElementsByClassName('ev-email-capture-form-error')[0];
    var uconsentName = uconsentForm.dataset.uconsentName;
    var method = uconsentForm.dataset.consentMethod;
    var args = {
      consent: uconsentName,
      onSuccess: function onSuccess() {
        if (!uconsentFormInput || !uconsentFormSuccess) return;
        uconsentFormSuccess.style.display = 'block';
        uconsentFormInput.style.opacity = 0;
      },
      onError: function onError() {
        if (!uconsentFormError) return;
        uconsentFormError.style.display = 'block';
      }
    };
    uConsent(method, args);
  });
};

export { init as default };
