import _toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';

var init = function init() {
  var buttons = _toConsumableArray(document.querySelectorAll('[data-accordion-preview-button="accordion-preview-button"]'));
  var contents = _toConsumableArray(document.querySelectorAll('[data-accordion-preview-content="accordion-preview-content"]'));
  buttons.forEach(function (button, index) {
    var content = contents[index];
    button.addEventListener('click', function () {
      var accordionOpen = button.dataset.accordionPreviewOpen === 'true';
      button.setAttribute('data-accordion-preview-open', !accordionOpen);
      if (accordionOpen) {
        var _content$getAttribute;
        content.setAttribute('class', (_content$getAttribute = content.getAttribute('class')) === null || _content$getAttribute === void 0 ? void 0 : _content$getAttribute.replace('ev-accordion-preview-visible', 'ev-accordion-preview-hidden'));
        button.innerHTML = button.innerHTML.replace('Read less', 'Read more');
      } else {
        var _content$getAttribute2;
        content.setAttribute('class', (_content$getAttribute2 = content.getAttribute('class')) === null || _content$getAttribute2 === void 0 ? void 0 : _content$getAttribute2.replace('ev-accordion-preview-hidden', 'ev-accordion-preview-visible'));
        button.innerHTML = button.innerHTML.replace('Read more', 'Read less');
      }
    });
  });
};

export { init as default };
